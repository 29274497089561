import { camelCase, keyBy } from 'lodash';
import reframe from 'reframe.js';
import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import Page from './Page';
import Loading from './Loading';
import Footer from './Footer';
import louisCatoImg from './images/lc.jpg';
import { SocialIcon } from 'react-social-icons';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const App = () => {
  const [data, setData] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGallery, setIsLoadingGallery] = useState(true);
  const [isLoadingInstagram, setIsLoadingInstagram] = useState(true);
  const [isPreloadingImages, setIsPreloadingImages] = useState(true);
  const [latestInsta, setLatestInsta] = useState(null);
  const [instagramSuccess, setInstagramSuccess] = useState(true);
  const sectionAbout = useRef(null);
  const sectionCatoCovers = useRef(null);
  const sectionLateShow = useRef(null);
  const sectionContact = useRef(null);
  const sectionAppearances = useRef(null);
  const [socials, setSocials] = useState({
    twitter: 'https://twitter.com/realLouisCato',
    instagram: 'https://www.instagram.com/reallouiscato/',
    tiktok: 'https://www.tiktok.com/@reallouiscato',
    facebook: 'https://www.facebook.com/realLouisCato/',
    youtube: 'https://www.youtube.com/@louisism'
  });

  const scrollToSection = (event, section) => {
    event.preventDefault();
    section.current.scrollIntoView({behavior: "smooth"})
  };

  useEffect(() => {
    const getWordpressData = async function () {
      const handleWordpressError = error => {
          return new Response(JSON.stringify({
              code: 400,
              message: 'Network error',
          }));
      };
      const pagesBySlug = [
        'about',
        'the-late-show',
        'contact'
      ];
      const wordpressResponse = await fetch(`${process.env.REACT_APP_WP_REST_URL}/pages?slug=${pagesBySlug.toString()}`).catch(handleWordpressError);
      if (!wordpressResponse.ok) {
        throw new Error(`Oops, there was a problem. Please try again later.`);
      }
      const wordpressJson = await wordpressResponse.json();
      setTimeout(function(){
        setData(keyBy(wordpressJson, page => {
          return camelCase(page.slug);
        }));
      }, 1000);
      setIsLoading(false);
    }
    getWordpressData();
  }, []);

  useEffect(() => {
    const getWordpressData = async function () {
      const handleWordpressError = error => {
          return new Response(JSON.stringify({
              code: 400,
              message: 'Network error',
          }));
      };
      const pagesBySlug = [
        'about',
        'the-late-show',
        'contact'
      ];
      const wordpressResponse = await fetch(`${process.env.REACT_APP_WP_REST_URL}/gallery-item?_embed`).catch(handleWordpressError);
      if (!wordpressResponse.ok) {
        throw new Error(`Oops, there was a problem. Please try again later.`);
      }
      const wordpressJson = await wordpressResponse.json();
      setGallery(wordpressJson);
      setIsLoadingGallery(false);
    }
    getWordpressData();
  }, []);

  useEffect(() => {
    const getInstagramData = async function () {
      const handleInstagramError = error => {
          return new Response(JSON.stringify({
              code: 400,
              error: true,
              message: 'Network error',
          }));
      };
      const instagramResponse = await fetch(`${process.env.REACT_APP_IG_URL}IGQVJYRUp0Y0FWNF92YnJoYmJ6TEFnOTRfZAG85ZAGxnd1pwVU5zaWV4YTBYa0g0YkZAXYVdwRTFiWS1zMzhvNkxQMVl5c1JWb2JUeGVOSi04VnF5VUNNdHh6NlBEVlZA0WEdkN0lnaXlQZA1lQcThFQ0NSQgZDZD`).catch(handleInstagramError);
      const instagramJson = await instagramResponse.json();
      if (!instagramJson.error) {
        const latestVideos = instagramJson.data.filter(ig => {
          return ig.media_type === 'VIDEO' && ig.caption.toLowerCase().indexOf('#catocovers') > -1;
        });
        setLatestInsta(latestVideos.slice(0, 3));
        setInstagramSuccess(true);
      } else {
        setInstagramSuccess(false);
      }
      setIsLoadingInstagram(false);
    }
    getInstagramData();
  }, []);

  useEffect(() => {
    if (!isLoading && !isPreloadingImages && !isLoadingInstagram && !isLoadingGallery) {
      reframe('iframe');
    }
  }, [isLoading, isPreloadingImages, isLoadingInstagram, isLoadingGallery]);

  const Site = (
    <div className="App">
      <header className="main-header">
        <div className="fade-in">
          <div className="photo-bg" style={{backgroundImage: `url(${louisCatoImg})` }}></div>
          <div className="title-and-menu slide-in-left">
            <h1 className="site-title slide-in-left">Louis Cato</h1>
            <div className="menu slide-in-left">
              <ul>
                <li>
                  <a href="#about" onClick={(e) => scrollToSection(e, sectionAbout)}>About</a>
                </li>
                <li>
                  <a href="#lateshow" onClick={(e) => scrollToSection(e, sectionLateShow)}>The Late Show</a>
                </li>
                {instagramSuccess &&
                <li className="cato-covers-link">
                  <a href="#catocovers" onClick={(e) => scrollToSection(e, sectionCatoCovers)}><span>#</span>catocovers</a>
                </li>
                }
                <li>
                  <a href="#appearances" onClick={(e) => scrollToSection(e, sectionAppearances)}>Appearances</a>
                </li>
                <li>
                  <a href="#contact" onClick={(e) => scrollToSection(e, sectionContact)}>Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <Page
        navRef={sectionAbout}
        data={data?.about}
        sectionColor={'dark'}
        leftAlignText={true}
      />

      <Page
        navRef={sectionLateShow}
        data={data?.theLateShow}
        sectionColor={'light'}
      />

      {instagramSuccess &&
      <div className="section centered-section section-dark" ref={sectionCatoCovers}>
        <div className="video-content">
          <h2>#catocovers</h2>
          <div className="video-container">
            {latestInsta?.length && latestInsta.map((ig, index) => {
              return (
                <video
                    key={index}
                    controls
                    width="250"
                    src={ig.media_url}
                    poster={ig.thumbnail_url}
                  >
                    Sorry, your browser doesn't support embedded videos. <a rel="noreferrer" href="https://www.instagram.com/reallouiscato/" target="_blank">Go to Instagram to watch</a>
                  </video>
              );
            })}
          </div>
          {latestInsta?.length && latestInsta[0].permalink &&
            <a className="button-link" rel="noreferrer" href={latestInsta[0].permalink} target="_blank">View on Instagram</a>
          }
        </div>
      </div>
      }

      <div className="section centered-section section-light" ref={sectionAppearances}>
       <div className="text-content">
        <h2>Appearances</h2>
       </div>
        <div className="appearances-container">
          <Carousel
            autoPlay={true}
            interval={3000}
            centerMode={true}
            centerSlidePercentage={100}
            dynamicHeight={false}
            infiniteLoop={false}
          >
            {gallery?.length && gallery.map((galleryItem, index) => {
              return <div key={index}>
                  <img src={galleryItem?._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url} alt={galleryItem?._embedded['wp:featuredmedia'][0].alt_text} />
                  <div className="gallery-caption" dangerouslySetInnerHTML={{ __html: galleryItem.excerpt.rendered }}/>
              </div>
            })}
          </Carousel>
        </div>
      </div>

      <div className="section section-dark" ref={sectionContact}>
        <div className="text-content">
          {data?.contact && <h2 dangerouslySetInnerHTML={{ __html: data.contact.title.rendered }} />}
          <div className="two-columns">
            {data?.contact && <div dangerouslySetInnerHTML={{ __html: data.contact.content.rendered }} />}
            <div className="socials">
              <div className="a-social">
                <SocialIcon
                  url={socials.twitter}
                  bgColor={'#B4D8D8'}
                />
                <a href={socials.twitter} rel="noreferrer" target="_blank">Twitter</a>
              </div>
              <div className="a-social">
                <SocialIcon
                  url={socials.instagram}
                  bgColor={'#B4D8D8'}
                />
                <a href={socials.instagram} rel="noreferrer" target="_blank">Instagram</a>
              </div>
              <div className="a-social">
                <SocialIcon
                  url={socials.tiktok}
                  bgColor={'#B4D8D8'}
                />
                <a href={socials.tiktok} rel="noreferrer" target="_blank">TikTok</a>
              </div>
              <div className="a-social">
                <SocialIcon
                  url={socials.facebook}
                  bgColor={'#B4D8D8'}
                />
                <a href={socials.facebook} rel="noreferrer" target="_blank">Facebook</a>
              </div>
              <div className="a-social">
                <SocialIcon
                  url={socials.youtube}
                  bgColor={'#B4D8D8'}
                />
                <a href={socials.youtube} rel="noreferrer" target="_blank">YouTube</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )

  return !isLoading && !isPreloadingImages && !isLoadingInstagram && !isLoadingGallery ? Site : <Loading
    isLoading={isLoading}
    isLoadingInstagram={isLoadingInstagram}
    isPreloadingImages={isPreloadingImages}
    isLoadingGallery={isLoadingGallery}
    img={louisCatoImg}
    onPreloadImg={setIsPreloadingImages}
  />;
}

export default App;
