import React from 'react';

const Page = ({data, leftAlignText, navRef, sectionColor}) => {
    let sectionClasses = 'section centered-section ';
    const sectionColorClass = sectionColor === 'dark' ? 'section-dark' : 'section-light';
    sectionClasses = sectionClasses + sectionColorClass;

    let textClasses = 'text-content';
    if (leftAlignText) {
        textClasses = textClasses + ' text-left';
    }

    return (
        <div className={sectionClasses} ref={navRef}>
            <div className={textClasses}>
                {data && <h2 dangerouslySetInnerHTML={{ __html: data.title.rendered }} />}
                {data && <div dangerouslySetInnerHTML={{ __html: data.content.rendered }} />}
            </div>
          </div>
    );
}

export default Page;
