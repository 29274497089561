import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div className="site-footer">
            <p>&copy; {currentYear} Louis Cato</p>
        </div>
    );
}

export default Footer;
